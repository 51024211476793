import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { CardLogin } from './CardLogin/CardLogin';
import { useFetch } from '../../hooks/useFetch';
import jwt from 'jwt-decode' // import dependency

import './Login.css'
import { images } from '../../helpers/images';
import { NavLink, useLocation } from 'react-router-dom';
import { Footer } from '../../components/Footer/FooterLogin';
import { useAxios } from '../../hooks/useAxios';
import queryString from 'query-string';


export const Login = ({ history }) => {

    const { dispatch } = useContext(AuthContext);

    const [fetchParametros, fetchParametrosData] = useFetch('GET', `/parametros`)

    const [fetchLoginEmpresa, fetchLoginEmpresaData] = useAxios('post', `/auth/login`)

    const [fetchLoginConsultor, fetchLoginConsultorData] = useAxios('post', `/auth/login`)

    const [fetchLoginAdmin, fetchLoginAdminData] = useAxios('post', `/auth/login`)

    const [datosIncorrectos, setDatosIncorrectos] = useState(false)

  
    useEffect(() => {

        console.log('fetchLoginUserData', fetchLoginEmpresaData, fetchLoginConsultorData, fetchLoginAdminData)
        if (fetchLoginEmpresaData.data || fetchLoginConsultorData.data || fetchLoginAdminData.data) {
            const token = fetchLoginEmpresaData?.data?.data?.data?.token || fetchLoginConsultorData?.data?.data?.data?.token || fetchLoginAdminData?.data?.data?.data?.token

            if (token) {

                const lastPath = localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_lastPath') || '/';
                const lang = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE))?.lang || process.env.REACT_APP_LANGUAGE_DEFAULT;

                const data = jwt(token)
                console.log(data)
                dispatch({
                    type: types.login,
                    payload: {
                        ...data,
                        token: token,
                    },
                    lang
                });

                // history.replace(lastPath);
                history.replace('/');

            } else {

                setDatosIncorrectos(true)
            }
        }

    }, [fetchLoginEmpresaData, fetchLoginConsultorData, fetchLoginAdminData])

    
    const location = useLocation()

    const { role } = queryString.parse(location.search);
    console.log(role)

    const [areaAdmin, setAreaAdmin] = useState(role === 'admin' ? true : false)

    console.log(process.env.REACT_APP_ENVIRONMENT)
    


    return (
        <div className="App">
            <header className="App-header min-vh-100">
                <section id="login-start" className="float-start flex-grow-1">
                    <img src={images('./logo.png')} className="card-img" alt="logo" id="logo-login" />
                    <CardLogin
                        datosIncorrectos={datosIncorrectos}
                        setDatosIncorrectos={setDatosIncorrectos}

                        fetchLoginEmpresaData={fetchLoginEmpresaData} 
                        fetchLoginEmpresa={fetchLoginEmpresa}

                        fetchLoginConsultorData={fetchLoginConsultorData} 
                        fetchLoginConsultor={fetchLoginConsultor}

                        fetchLoginAdminData={fetchLoginAdminData} 
                        fetchLoginAdmin={fetchLoginAdmin}

                        areaAdmin={areaAdmin}
                    />
                    
                    <Footer setAreaAdmin={setAreaAdmin} />

                </section>
                <section id='login-end' className='d-flex flex-column justify-content-between shadow fw-light'>
                    <div className="card-test-info p-2 mt-5">
                        <h4 className='mb-3'>Declaración Ambiental de Productos de Construcción</h4>
                        <p>El Programa DAPconstrucción® es un programa que aglutina empresas fabricantes de productos y materiales de construcción que tienen un compromiso con la sostenibilidad y el medio ambiente, y desean avanzar en el análisis de los impactos medioambientales de sus productos.</p>
                        <p className='mb-2'> <a href="https://www.cateb.cat/dapconstruccion/" target='_blank' rel="noopener noreferrer">Más información</a> </p>
                        <p className='mb-2'> <a href="https://www.cateb.cat/dapcons/" target='_blank' rel="noopener noreferrer">Més informació</a> </p>
                        <p className='mb-0'> <a href="https://www.cateb.cat/dapconstruccion-programme/" target='_blank' rel="noopener noreferrer">More information</a> </p>
                    </div>
                    <div className="card-test-info p-2 mb-2 mb-lg-4">
                        <p className='mb-0'>
                            Contáctanos a través de <a href="mailto:sostenible@cateb.cat">sostenible@cateb.cat</a> para más información sobre DAPcons®
                        </p>
                    </div>
                </section>

            </header>

    

        </div>
    )
}
